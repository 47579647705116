@use "sass:math";

// core
.p-metergroup {
  display: flex;
}

.p-metergroup-meters {
  display: flex;
}

.p-metergroup-vertical .p-metergroup-meters {
  flex-direction: column;
}

.p-metergroup-labels {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-metergroup-vertical .p-metergroup-labels {
  align-items: start;
}

.p-metergroup-labels-vertical {
  flex-direction: column;
}

.p-metergroup-label {
  display: inline-flex;
  align-items: center;
}

.p-metergroup-label-marker {
  display: inline-flex;
}

// theme
.p-metergroup {
  gap: $inlineSpacing * 2;

  .p-metergroup-meters {
    background: $progressBarBg;
    border-radius: $borderRadius;
  }

  .p-metergroup-meter {
    border: $progressBarBorder;
    background: $progressBarValueBg;
  }

  .p-metergroup-labels {
    .p-metergroup-label {
      gap: $inlineSpacing;
    }

    .p-metergroup-label-marker {
      background: $progressBarValueBg;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100%;
    }

    .p-metergroup-label-icon {
      width: 1rem;
      height: 1rem;
    }

    &.p-metergroup-labels-vertical {
      gap: $inlineSpacing;
    }

    &.p-metergroup-labels-horizontal {
      gap: $inlineSpacing * 2;
    }
  }

  &.p-metergroup-horizontal {
    flex-direction: column;

    .p-metergroup-meters {
      height: 0.5rem;
    }

    .p-metergroup-meter:first-of-type {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }

    .p-metergroup-meter:last-of-type {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }

  &.p-metergroup-vertical {
    flex-direction: row;

    .p-metergroup-meters {
      width: 0.5rem;
      height: 100%;
    }

    .p-metergroup-meter:first-of-type {
      border-top-left-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
    }

    .p-metergroup-meter:last-of-type {
      border-bottom-left-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }
}
