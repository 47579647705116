$colors: (
  "blue": (
    50: #f5f8fc,
    100: #d9e5f4,
    200: #b1c9e8,
    300: #99b8e0,
    400: #79a4da,
    500: #407ec9,
    600: #0e4fbc,
    700: #003594,
    800: #002363,
    900: #001131,
  ),
  "yellow": (
    50: #fffcf5,
    100: #fff3da,
    200: #fceec3,
    300: #f5e1a4,
    400: #f9da8c,
    500: #fdd086,
    600: #e0a72d,
    700: #c59014,
    800: #83560d,
    900: #41291a,
  ),
  "pink": (
    50: #fff6f7,
    100: #edbcc8,
    200: #dba0b6,
    300: #c9809e,
    400: #c05983,
    500: #a73a64,
    600: #9a2855,
    700: #872651,
    800: #5a0d30,
    900: #2d2a2a,
  ),
  "teal": (
    50: #f2fffb,
    100: #d5f2f2,
    200: #b1e4e3,
    300: #89e0df,
    400: #71d8d6,
    500: #64ccc9,
    600: #129f93,
    700: #007367,
    800: #094d2e,
    900: #1b2612,
  ),
  "surface": (
    0: #ffffff,
    50: #fafafa,
    100: #f5f5f5,
    200: #eeeeee,
    300: #e0e0e0,
    400: #bdbdbd,
    500: #9e9e9e,
    600: #757575,
    700: #616161,
    800: #424242,
    900: #212121,
  ),
);

// Primeng Theme Specific Variables
$primaryColor: #003594;
$primaryDarkColor: #002363;
$primaryDarkerColor: #001131;
$primaryLightColor: #d9e5f4;
$primaryTextColor: #ffffff; // Text color when background is primary color
