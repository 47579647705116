.p-toast {
  .p-toast-message {
    .p-toast-tutorial-message-text {
      margin: $toastTutorialMessageTextMargin;
      width: 17rem;

      .p-toast-summary {
        font-weight: $toastTitleFontWeight;
        margin: 0 1rem 0 1rem;
        font-size: 16px;
      }
      .p-toast-detail {
        margin: 0.5rem 1rem 0 1rem;
        font-family: "Roboto";
        font-size: 14px;
      }
    }

    .p-toast-icon-close-icon {
      font-size: $toastIconFontSize;
    }

    .p-toast-tutorial-message-icon,
    .p-toast-tutorial-icon-close-icon {
      font-size: $toastIconFontSize;
      font-weight: 400;
      font-family: Font Awesome 6 Pro;
      font-style: normal;
    }

    .p-toast-detail {
      font-family: "Roboto";
      font-size: 14px;
    }

    &.p-toast-message-success {
      border-left: 0.25rem solid #094d2e;
    }

    &.p-toast-message-error {
      border-left: 0.25rem solid #872651;

      .p-toast-message-icon {
        font-weight: 400;
        font-style: Font Awesome 6 Pro;
      }
    }

    &.p-toast-message-tutorial {
      font-style: roboto;
      font-size: 16px;
      background: $tutorialMessageBg;
      border: $tutorialMessageBorder;
      border-width: $messageBorderWidth;
      color: $tutorialMessageTextColor;
      border-left: 0.25rem solid #003594;
      align-items: center;

      .p-toast-message-icon,
      .p-toast-icon-close {
        color: $tutorialMessageIconColor;
      }
      .p-toast-detail {
        width: 17rem;
      }
    }
  }
}
