.p-tag {
  &.light-blue {
    background-color: var(--blue-100);
    color: $primaryColor;
    font-size: 0.875rem;
    font-weight: 400;
    cursor: pointer;
  }

  &.completed {
    background-color: #d5f2f2;
    color: #007367;
  }

  &.info {
    background-color: var(--surface-c);
    color: $textSecondaryColor;
  }

  &.cancelled {
    background-color: #d9e5f4;
    color: $primaryColor;
  }

  &.in-progress {
    background-color: #fff3da;
    color: #c59014;
  }

  &.draft {
    background-color: #edbcc8;
    color: #872651;
  }

  &.portfolio-tag {
    background: #eee6f8;
    color: #521bb6;
    padding: 2px 6px;
    .p-tag-value {
      line-height: 20px;
      font-size: 14px;
    }
  }
}
