.p-dialog {
  box-shadow: $dialogShadow;

  .p-dialog-header {
    height: 64px;

    .p-dialog-header-icon {
      width: $dialogHeaderIconSize;
      height: $dialogHeaderIconSize;
      color: $dialogHeaderCloseIconColor;
    }
  }
}

.p-dialog-mask.p-component-overlay {
  background-color: $maskBg !important;

  &.light-mask {
    background-color: $lightMaskBg !important;
  }
}
