@import "primeng";

body {
  padding: 0;
  margin: 0;
  color: $textColor;
  font-family: $fontFamily;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: $fontWeight;
  background-color: #f8f9fa;
}

a {
  color: $primaryColor;
  font-weight: $fontWeight;
}

.text-primary {
  color: $primaryColor;
}

.text-secondary {
  color: $textSecondaryColor;
}

.text-success {
  color: $msSuccessBg !important;
}

.text-warning {
  color: $msWarningBg !important;
}

.text-error {
  color: $msErrorBg !important;
}
.text-gray {
  color: $text-gray !important;
}

.p-field {
  margin-bottom: 1.5rem;
}

p-checkbox {
  + small {
    padding-left: 0.5rem;
  }
}

.ms__header {
  z-index: 3000 !important;
}

.ms__sidebar {
  z-index: 2000 !important;
}

// TODO: by default it set to `transition: all .2s;` (by primeng)
// It is a workaround for sidebar: on collapse action, button label were disappearing slowly then sidebar itself
.p-button,
.p-button-label,
.p-button:focus .p-button-label {
  transition: none !important;
}

.-translate-50 {
  transform: translate(-50%, -50%);
}

button.p-component:disabled {
  opacity: 0.3;
}

.p-field-radiobutton {
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;

  > label {
    margin-left: 0.5rem;
    line-height: 1;
  }
}
