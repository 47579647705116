/// Border of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBorder: 0 none;

/// Background of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBg: $panelContentBg;

/// Box shadow of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContainerShadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);

/// Box shadow of a dialog
/// @group overlay
$dialogShadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1),
  0px 1px 4px -1px rgba(0, 0, 0, 0.1);

/// Background of a dialog header
/// @group overlay
$dialogHeaderBg: #ffffff;

/// Border of a dialog header
/// @group overlay
$dialogHeaderBorder: 0.063rem solid #dee2e6;

/// Text color of a dialog header
/// @group overlay
$dialogHeaderTextColor: $panelHeaderTextColor;

/// Font weight of a dialog header
/// @group overlay
$dialogHeaderFontWeight: 600;

/// Font size of a dialog header
/// @group overlay
$dialogHeaderFontSize: 1.25rem;

/// Padding of a dialog header
/// @group overlay
$dialogHeaderPadding: 1.5rem 1.5rem 1rem 1.5rem;

/// Width/Height of the dialog header icon
/// @group overlay
$dialogHeaderIconSize: 1.5rem;

/// Color of the dialog header close icon
/// @group overlay
$dialogHeaderCloseIconColor: #003594;

/// Padding of a dialog content
/// @group overlay
$dialogContentPadding: 1.5rem 1.5rem 0 1.5rem;

/// Border of a dialog footer
/// @group overlay
$dialogFooterBorder: none;

/// Padding of a dialog footer
/// @group overlay
$dialogFooterPadding: 1.5rem;

/// Margin of a dialog footer
/// @group overlay
$dialogFooterMargin: 0 -1.5rem;

/// Margin of dialog footer content
/// @group overlay
$dialogFooterContentMargin: 0 1.5rem;

/// Background of a tooltip
/// @group overlay
$tooltipBg: $textColor;

/// Text color of a tooltip
/// @group overlay
$tooltipTextColor: #ffffff;

/// Padding of a tooltip
/// @group overlay
$tooltipPadding: $inputPadding;
