.p-paginator {
  .p-inputtext {
    color: $text-gray;
  }
  .p-dropdown-trigger-icon {
    color: $text-gray;
  }
  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    top: 2px;
    position: relative;
    font-weight: 400;
  }

  .p-dropdown {
    border: 0px;
  }
}
