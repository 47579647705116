.p-button {
  &.p-button-outlined {
    background-color: $outlinedButtonBgColor;
    color: $outlinedButtonTextColor;
    border-color: $outlinedButtonBorderColor;

    &:enabled:hover,
    &:enabled:active {
      background-color: $outlinedButtonHoverBgColor;
      color: $outlinedButtonTextColor;
      border-color: $outlinedButtonBorderColor;
    }
  }
}
