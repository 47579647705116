@import "./variables/colors";

$fontFamily: "Roboto", "Helvetica Neue", sans-serif;

$inputBorderColor: #b1c9e8;

$menuBorder: none;

// MS custom variables
$msSuccessBg: #129f93;
$msWarningBg: #fdd086;
$msErrorBg: #e41809;
$text-gray: #6c757d;

$msCalendarDatePickerHover: #6366f1;
$msDatePickerFontWeight: 600;
$msDatePickerPadding: 0.5rem;

@import "./variables/navbar";

$msSidebarDefaultWidth: 16.5rem;
$msSidebarCollapsedWidth: 1.25rem;

// Mandatory Designer Variables
@import "./variables/general";
@import "./variables/form";
@import "./variables/button";
@import "./variables/panel";
@import "./variables/_data";
@import "./variables/_overlay";
@import "./variables/_message";
@import "./variables/_menu";
@import "./variables/_media";
@import "./variables/_misc";

:root {
  --font-family: #{$fontFamily};
  --text-color: #{$textColor};
  --text-color-secondary: #{$textSecondaryColor};

  --primary-color: #{$primaryColor};
  --primary-color-text: #{$primaryTextColor};
  --primary-darker-color-text: #{$primaryDarkerColor};

  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};

  --border-radius: #{$borderRadius};

  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --surface-light-blue: #b1c9e8;

  --ms-navbar-height: #{$msTopNavigationBarHeight};

  --ms-sidebar-default-width: #{$msSidebarDefaultWidth};
  --ms-sidebar-collapsed-width: #{$msSidebarCollapsedWidth};
}

@import "./mixins/propertysearchtree";
